import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export const CACHING_ENABLED = new HttpContextToken<boolean>(() => true);

@Injectable({
  providedIn: 'root',
})
export class HeadersInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    handler: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let reqWithHeader = req;
    
    if (req.context.get(CACHING_ENABLED)) {
      const token = localStorage.getItem('token') || '';
      if (localStorage) {
        reqWithHeader = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
      }
    }
    return handler.handle(reqWithHeader);
  }
}
