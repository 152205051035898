import { environment } from '../../../environments/environment';

export class Uris {
  public static API = environment.API_URL;

  public static AUTH_LOGIN = `${Uris.API}auth/login/`;
  public static AUTH_LOGOUT = `${Uris.API}auth/logout/`;

  public static USERS = `${Uris.API}users/`;
  public static REGISTER = `${Uris.API}register_user/`;
  public static REGISTER_ADMIN = `${Uris.API}register_user_admin/`;

  public static REFERENCE = `${Uris.API}my_refer_code/`;

  public static PAYMENT = `${Uris.API}payments/`;

  public static USER_CLIENT = `${Uris.API}user_client/`;

  public static BILLING = `${Uris.API}billing`;
  public static NETWORK = `${Uris.API}my_network`;

  public static USER_NETWORK = `${Uris.API}user_network`;

  public static SUCCESS_CASES = `${Uris.API}success_cases`;

  public static PASSWORD_RESET = `${Uris.API}password_reset`;
}
